import React from 'react';

import { TripsGroup } from '../../model/Trip';
import RouteEnum from '../../RouteEnum';
import Link from '../Link';
import MetaTags from '../MetaTags';
import T from '../Translate';

import HandoverInAppNotice from './HandoverInAppNotice';
import TripsContainer from './TripsContainer';

const Links = () => (
  <div className="text-right mb-3">
    <Link className="link-more mr-3" to={RouteEnum.TRIPS_CANCELLED}>
      <T id="trips.cancelled.title" />
      <i className="icon icon-arrow-right" />
    </Link>
    <Link className="link-more" to={RouteEnum.TRIPS_HISTORY}>
      <T id="trips.history.title" />
      <i className="icon icon-arrow-right" />
    </Link>
  </div>
);

export default function UserTrips() {
  return (
    <main className="main-container container container--top pb-6">
      <MetaTags title="trips.meta.title" />
      <Links />

      <h1 className="display-1 mb-4">
        <T id="trips.current.title" />
      </h1>

      <HandoverInAppNotice />

      <TripsContainer tripGroup={TripsGroup.IN_PROGRESS}>
        <T id="trips.current.empty" />
      </TripsContainer>

      <hr />

      <h3 className="display-3 my-4">
        <T id="trips.planned.title" />
      </h3>
      <TripsContainer tripGroup={TripsGroup.PLANNED}>
        <T id="trips.planned.empty" />
      </TripsContainer>

      <hr />

      <h3 className="display-3 my-4">
        <T id="trips.requested.title" />
      </h3>
      <TripsContainer tripGroup={TripsGroup.REQUESTED}>
        <T id="trips.requested.empty" />
      </TripsContainer>
    </main>
  );
}
