import React, { useState } from 'react';
import cn from 'classnames';

import Tracking from '../../services/Tracking';
import MobileAppLinks from '../MobileAppLinks';
import T from '../Translate';

import styles from './handoverInAppNotice.module.scss';

export default function HandoverInAppNotice() {
  const STORAGE_KEY = 'hoppygo_handover_notice';
  const [opened, setOpened] = useState(() => {
    if (typeof window === 'undefined') return true;
    const hasBeenClosed = window.localStorage.getItem(STORAGE_KEY);
    return !hasBeenClosed;
  });

  const handleClose = () => {
    setOpened(false);
    window.localStorage.setItem(STORAGE_KEY, 'true');
  };

  return (
    <div className={cn(styles.container, { opened })}>
      <button className={styles.closeButton} onClick={handleClose} type="button">
        <i className="icon icon-close" />
      </button>
      <div className={styles.body}>
        <T as="small" className={styles.label} id="global.handoverNotice.newLabel" />
        <T as="h3" className={styles.header} id="global.handoverNotice.title" />
        <T as="p" id="global.handoverNotice.description" />
      </div>
      <MobileAppLinks
        className={styles.mobileApps}
        detectSystem
        height={40}
        onClick={platform => Tracking.track('TRIP_HANDOVER_IN_APP_CLICKED', platform)}
      />
    </div>
  );
}
