import React from 'react';

import PageLayout from '../../views/Layout/Page';
import UserTrips from '../../views/Trip/UserTrips';

export default function TripsIndexPage() {
  return (
    <PageLayout pageview="my_trips" private>
      <UserTrips />
    </PageLayout>
  );
}
